/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// react plugin used to create charts
import { Line } from 'react-chartjs-2';
// react plugin for creating vector maps
// import { VectorMap } from 'react-jvectormap';

import FacebookLogin from 'react-facebook-login';
/* global FB*/
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner
} from 'reactstrap';

import axios from 'axios';
import config from '../../../config';
// core components

axios.defaults.baseURL = config.coreAPI;


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.loadFbLoginApi();
    this.state = {
      data: null,
      period: 'month',
      data_im: null,
      period_im: 'month',
      login : false
    };
    this.handleFBLogin = this.handleFBLogin.bind(this);
  }
  loadFbLoginApi() {
    if(!this.scriptPromise) {
      this.scriptPromise = new Promise((resolve, reject) => {
        window.fbAsyncInit = () => {
          // window.fbAsyncInit = function() {
            FB.init({
              appId            : '725737674281738',
              autoLogAppEvents : true,
              xfbml            : true,
              version          : 'v4.0'
            });
          // };
          resolve();
        };
        if (typeof(FB) == 'undefined') {
          (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = "//connect.facebook.net/es_LA/sdk.js";
              fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        }
      })
    }
    return this.scriptPromise;
    // window.fbAsyncInit = function() {
    //   FB.init({
    //     appId            : '725737674281738',
    //     autoLogAppEvents : true,
    //     xfbml            : true,
    //     version          : 'v6.0'
    //   });
    // };
      // Load the SDK asynchronously
    // (function(d, s, id) {
    //     var js, fjs = d.getElementsByTagName(s)[0];
    //     if (d.getElementById(id)) return;
    //     js = d.createElement(s); js.id = id;
    //     js.src = "//connect.facebook.net/es_LA/sdk.js";
    //     fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'facebook-jssdk'));
  }
  componentDidMount(){
    this.loadFbLoginApi().then(()=>{
      FB.getLoginStatus((res)=>{
        if(res.status === "connected"){
          this.setState({...this.state, login: true})
          localStorage.setItem("login", this.state.login);
        }else{
          this.setState({...this.state, login: false})
          localStorage.setItem("login", this.state.login);
        }
      })
    });
    // setTimeout(() => {
    // }, 1000);
    // FB.XFBML.parse();
    // setTimeout(() => {
    //   FB.Event.subscribe("auth.statusChange", (res)=>{
    //     console.log(res)
    //     if (res.authResponse) {
    //       this.responseFacebook(res.authResponse.userID,res.authResponse.accessToken)
    //       FB.api('/me', (userinfo)=> {
    //         FB.api(
    //           "/me/picture",
    //           {
    //               "redirect": false,
    //               "height": 200,
    //               "width": 200,
    //               "type": "normal"
    //           },
    //          (response) => {
    //             if (response && !response.error) {
    //               // console.log(userinfo.name , response.data.url)
    //               localStorage.setItem('user_info',JSON.stringify({
    //                 user: {
    //                   name: userinfo.name,
    //                   picture: response.data.url
    //                 }
    //               }))
    //             }
    //           }
    //         );
  
    //       });
    //      }
    //   })
    // }, 1000);


    if (localStorage.getItem('Fb_exchange_token')){
      this.get_facebook_insights();
      this.get_facebook_insights_im();
    }
  }

  handleFBLogin() {
    FB.getLoginStatus((res)=>{
      if(res.status === "connected"){
        FB.logout((res)=>{
          this.setState({...this.state, login: false, data: null, data_im: null})
          localStorage.setItem("login", this.state.login);
          localStorage.removeItem("Fb_exchange_token");
          localStorage.removeItem("Fb_page_info");
          localStorage.removeItem("Fb_user_token");
          localStorage.removeItem("user_info");
        });
      }else{
        FB.login((res)=>{
          if (res.authResponse) {
            this.setState({...this.state, login: true})
            localStorage.setItem("login", this.state.login);
            this.responseFacebook(res.authResponse.userID,res.authResponse.accessToken)
            FB.api('/me', (userinfo)=> {
              FB.api(
                "/me/picture",
                {
                    "redirect": false,
                    "height": 200,
                    "width": 200,
                    "type": "normal"
                },
               (response) => {
                  if (response && !response.error) {
                    // console.log(userinfo.name , response.data.url)
                    localStorage.setItem('user_info',JSON.stringify({
                      user: {
                        name: userinfo.name,
                        picture: response.data.url
                      }
                    }))
                  }
                }
              );
    
            });
           }
        }, {scope: 'public_profile, email,read_insights, instagram_manage_insights, instagram_basic'});
      }
    })
  }

  componentWillUnmount(){
    // FB.Event.unsubscribe("auth.statusChange")
    // FB.Event.unsubscribe("auth.logout")
  }

  setBgChartData = (labels, values, period) => {
    this.setState({
      ...this.state,
      data : get_chart_engagement(labels, values, "Engagement"),
      period: period,
      is_loading: false,
      showButton: false
    });
  };
  setBgChartData_im = (labels, values, period) => {
    this.setState({
      ...this.state,
      data_im : get_chart_engagement(labels, values, "Impressions"),
      period_im: period,
      is_loading_im: false,
    });
  };

  get_facebook_insights = (period = "week") =>{
    this.setState({
      ...this.state,
      is_loading: true
    })
    let long_token = localStorage.getItem('Fb_exchange_token');
    let page_id = JSON.parse(localStorage.getItem('Fb_page_info'))["id"];
    axios.post('/insights/v1/facebook/page_post_insights',{"page_id": page_id, "page_token": long_token,
                            "metric": ["page_post_engagements"],
                            "date_preset" : "last_7d",
                            "period": period}).then((res)=>{
                              if(res["data"]["data"].length !== 0){
                                let data = res["data"]["data"][0]["values"];
                                let labels = data.map((item)=>item["end_time"].substr(0,10));
                                labels = labels.map((item)=>{let date = item.split('-'); return `${date[2]}/${date[1]}/${date[0]}`});
                                let values = data.map((item)=>item["value"]);
                                this.setBgChartData(labels,values,period)
                              }
                            });
  }

  get_facebook_insights_im = (period = "week") =>{
    this.setState({
      ...this.state,
      is_loading_im: true
    })
    let long_token = localStorage.getItem('Fb_exchange_token');
    let page_id = JSON.parse(localStorage.getItem('Fb_page_info'))["id"];
    axios.post('/insights/v1/facebook/page_post_insights',{"page_id": page_id, "page_token": long_token,
                            "metric": ["page_impressions_unique"],
                            "date_preset" : "last_7d",
                            "period": period}).then((res)=>{
                              if(res["data"]["data"].length !== 0){
                                let data = res["data"]["data"][0]["values"];
                                let labels = data.map((item)=>item["end_time"].substr(0,10));
                                labels = labels.map((item)=>{let date = item.split('-'); return `${date[2]}/${date[1]}/${date[0]}`});
                                let values = data.map((item)=>item["value"]);
                                this.setBgChartData_im(labels,values,period)
                              }
                            });
  }


  responseFacebook(user_id, user_token) {
    // let user_id = response['userID'];
    // let user_token = response['accessToken'];
    localStorage.setItem('Fb_user_token', user_token);
    axios
      .post('/insights/v1/facebook/fb_list_pages',{"user_id":user_id, "user_token": user_token})
      .then(res => {
        let page_token = res["data"]["data"][0]["access_token"];
        let page_id = res["data"]["data"][0]["id"];
        localStorage.setItem('Fb_page_info', JSON.stringify({"id": page_id,"token":page_token}));
        axios.post('/insights/v1/facebook/fb_exchange_token',{"token": page_token}).then( res => {
          let exchange_token = res["data"]["token"];
          localStorage.setItem('Fb_exchange_token', exchange_token);
          if (localStorage.getItem('Fb_exchange_token')){
            this.get_facebook_insights();
            this.get_facebook_insights_im();
          }
        });
      })
      .catch(err => {
        // console.log(err)
      });
  }

  button_facebook = ()=>{
    if(localStorage.getItem('user_info')){
      let user_info = JSON.parse(localStorage.getItem('user_info'))
      return (
      <div style={{display:"flex", alignItems: "center", marginBottom:"20px"}}>
        <img alt="" style={{height: "40px", width: "40px", marginRight: "10px", borderRadius: "50%"}} src={user_info['user']['picture']}></img>
        <h2 style={{margin: "0px"}}>{user_info['user']['name']}</h2>
      </div>
      )
    }else{
        return null;
    }
  }

  render() {
    const styles = {
      background: "#4267B2",
      color: "white",
      display: "flex",
      alignItems: "center",
      fontSize : "1.5em",
      padding: "10px 15px",
      borderRadius: "5px",
      userSelect: "none",
      cursor: "pointer"
    }
    return (
      <>
        <div className='content'>
          <Row style={{marginBottom: "20px"}}>
              <div style={styles}
              onClick={this.handleFBLogin}>
                <i className="fab fa-facebook-square" style={{marginRight: "15px", fontSize:"1.55em"}}></i>
                {/* {this.state.login ? "Cerrar sesión" :"Iniciar sesión"} */}
                {localStorage.getItem("login") === "true" ? "Cerrar sesión" :"Iniciar sesión"}
              </div>
          </Row>
          <Row>
            <Col xs='12'>
              {this.button_facebook()}
            </Col>
          </Row>
          <Row>
            {this.state.data == null? (<div></div>): 
            (<Col xs='12'>
              <Card className='card-chart'>
                <CardHeader>
                  <Row>
                    <Col className='text-left' sm='6'>
                      <CardTitle tag='h2'>Engagement</CardTitle>
                    </Col>
                    <Col sm='6'>
                      <ButtonGroup className='btn-group-toggle float-right' data-toggle='buttons'>
                        <Button
                          color='info'
                          id='0'
                          size='sm'
                          tag='label'
                          className={classNames('btn-simple', {
                            active: this.state.period === 'month'
                          })}
                          onClick={() => this.get_facebook_insights('month')}
                        >
                          <input defaultChecked name='options' type='radio' />
                          <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Mes</span>
                          <span className='d-block d-sm-none'>
                            <i className='tim-icons icon-single-02' />
                          </span>
                        </Button>
                        <Button
                          color='info'
                          id='1'
                          size='sm'
                          tag='label'
                          className={classNames('btn-simple', {
                            active: this.state.period === 'week'
                          })}
                          onClick={() => this.get_facebook_insights('week')}
                        >
                          <input name='options' type='radio' />
                          <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Semana</span>
                          <span className='d-block d-sm-none'>
                            <i className='tim-icons icon-gift-2' />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.is_loading ?
                    (<Spinner style={{marginLeft:"20px"}}></Spinner>)
                  :
                  (<div className='chart-area'>
                    <Line data={this.state.data} options={chartExample1.options} />
                  </div>)
                }
                </CardBody>
              </Card>
            </Col>)
            }
            {this.state.data ? (<Col xs='3'>{tactip(this.state.data, true, 'engagement')}</Col>): null}
            {this.state.data ? (<Col xs='3'>{tactip(this.state.data,false, 'engagement')}</Col>): null}
            {/* grafico 2 */}
            {this.state.data == null? (<div></div>):
            (<Col xs='12'>
              <Card className='card-chart'>
                <CardHeader>
                  <Row>
                    <Col className='text-left' sm='6'>
                      <CardTitle tag='h2'>Impressions unique</CardTitle>
                    </Col>
                    <Col sm='6'>
                      <ButtonGroup className='btn-group-toggle float-right' data-toggle='buttons'>
                        <Button
                          color='info'
                          id='0'
                          size='sm'
                          tag='label'
                          className={classNames('btn-simple', {
                            active: this.state.period_im === 'month'
                          })}
                          onClick={() => this.get_facebook_insights_im('month')}
                        >
                          <input defaultChecked name='options' type='radio' />
                          <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Mes</span>
                          <span className='d-block d-sm-none'>
                            <i className='tim-icons icon-single-02' />
                          </span>
                        </Button>
                        <Button
                          color='info'
                          id='1'
                          size='sm'
                          tag='label'
                          className={classNames('btn-simple', {
                            active: this.state.period_im === 'week'
                          })}
                          onClick={() => this.get_facebook_insights_im('week')}
                        >
                          <input name='options' type='radio' />
                          <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Semana</span>
                          <span className='d-block d-sm-none'>
                            <i className='tim-icons icon-gift-2' />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.is_loading_im ?
                    (<Spinner style={{marginLeft:"20px"}}></Spinner>)
                  :
                  (<div className='chart-area'>
                    <Line data={this.state.data_im} options={chartExample1.options} />
                  </div>)
                }
                </CardBody>
              </Card>
            </Col>)
            }
            {/* fin grafico 2 */}
            {this.state.data_im && (<Col xs='3'>{tactip(this.state.data_im, true, 'impressions')}</Col>)}
            {this.state.data_im && (<Col xs='3'>{tactip(this.state.data_im,false, 'impressions')}</Col>)}
          </Row>
          
        </div>
      </>
    );
  }
}

export default Dashboard;

const tactip = (data, max_bool=true, title="'engagement'")=>{
  let data_values = data["datasets"][0]["data"];
  let value_mm;
  if (max_bool){
    value_mm  = Math.max(...data_values);
  }else{
    value_mm  = Math.min(...data_values);
  }
  let count_data = data_values.filter((value)=>value === value_mm).length;
  let indices = data_values.map((e, i) => e === value_mm ? i : '').filter(String)

  let styles_value = {
    background: '#2280F7', 
    borderRadius:"5px",
    padding: "5px",
    display: "flex",
    justifyContent: "Center"
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {max_bool?" Mayor": "Menor"} {title}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div style={{display:'flex', justifyContent:'space-around'}}>
          <div>
            <p>Valor: </p><h3 style={styles_value}>{value_mm}</h3>
          </div>
          <div>
            {count_data!==1 ? <p>Última fecha:</p> : <p>Fecha:</p>}
            <h3>{data["labels"][indices[indices.length - 1]]}</h3>
          </div>
        </div>
        {count_data!==1 && (<div style={{textAlign: "justify"}}>
          <hr style={{background:'rgba(255,255,255,0.5)', marginTop:'0px'}}/>
          <p>Nota: Hay más de un dia con el valor {max_bool?" máximo": "mínimo"}, se muestra la última fecha con este valor.</p>
        </div>)}
      </CardBody>
    </Card>
  )
}

let chart_1_2_3_options = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent"
        },
        ticks: {
          // suggestedMin: 60,
          // suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ]
  }
};

function get_chart_engagement(labels, values, text){
  return {
    labels: labels,
    datasets: [
      {
        label: text,
        fill: true,
        borderColor: "#1f8ef1",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#1f8ef1",
        pointBorderColor: "rgba(255,255,255,0)",
        pointHoverBackgroundColor: "#1f8ef1",
        pointBorderWidth: 20,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 4,
        data: values
      }
    ]
  };
}


let chartExample1 = {
  data1: canvas => {
    let labels = ["JAN","FEB","MAR","APR"];
    let values = [80, 70, 90, 70];
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
      labels: labels,
      datasets: [
        {
          label: "Engagement",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: values
        }
      ]
    };
  },
  data2: canvas => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
      ],
      datasets: [
        {
          label: "Engagement",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120]
        }
      ]
    };
  },
  options: chart_1_2_3_options
};


/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from 'views/app-page/Dashboard/Dashboard';
import Status from 'views/app-page/Status/Status';
// import Tendency from 'views/app-page/Tendency/Tendency';
// import Audience from 'views/app-page/Audience/Audience';
// import Campaign from 'views/app-page/Campaign/Campaign';

const routes = [
  {
    path: '/dashboard',
    name: 'Facebook',
    icon: 'tim-icons icon-chart-pie-36',
    component: Dashboard,
    layout: '/app'
  },
  {
    path: '/status',
    name: 'Instagram',
    icon: 'tim-icons icon-chart-bar-32',
    component: Status,
    layout: '/app'
  },
  // {
  //   path: '/tendency',
  //   name: 'Tendencias',
  //   icon: 'tim-icons icon-chart-pie-36',
  //   component: Tendency,
  //   layout: '/app'
  // },
  // {
  //   path: '/audience',
  //   name: 'Audiencias',
  //   icon: 'tim-icons icon-chart-pie-36',
  //   component: Audience,
  //   layout: '/app'
  // },
  // {
  //   path: '/campaign',
  //   name: 'Campañas',
  //   icon: 'tim-icons icon-chart-pie-36',
  //   component: Campaign,
  //   layout: '/app'
  // }
];

export default routes;

import axios from 'axios';

import { LOGIN_USER, LOGOUT_USER } from '../constants/action-types';

import config from '../../src/config';

axios.defaults.baseURL = config.coreAPI;

export const fetchUser = () => dispatch => {
  const token = localStorage.token;
  if (token) {
    // Set axios headers to use authorization token for future requests.
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    // Fetch user
    
  } else {
    // User is not logged in. Redirect to login page.
    // dispatch(logoutUser());
  }
};

export const userRegisterFetch = form => dispatch => {
  return new Promise((resolve, reject) => {
    // Create user
    axios
      .post('/core/v1/auth/register/parent/', form, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        resolve();
      })
      .catch(error => {
        const { data } = error.response;

        if (data.detail === 'Invalid token.') {
          // Case where user logged out but token was not removed from headers.
          delete axios.defaults.headers.common['Authorization'];
        }
        reject(data);
      });
  });
};

export const userLoginFetch = form => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post('/core/v1/auth/login/', form, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        let user = res.data;
        // Store token in localStorage and save it to our redux store
        localStorage.setItem('token', user.user_token);
        // delete user.user_token;
        dispatch(loginUser(user));
        resolve();
      })
      .catch(error => {
        const { data } = error.response;

        if (data.detail === 'Invalid token.') {
          // Case where user logged out but token was not removed from headers.
          delete axios.defaults.headers.common['Authorization'];
        }

        reject(data);
      });
  });
};

export const userLogoutFetch = () => dispatch => {
  localStorage.removeItem('token'); // First, remove token from local storage
  dispatch(logoutUser()); // Then 'logout' user and redirect him to login page.

  axios
    .post('/core/v1/auth/logout/')
    .then(res => {
      delete axios.defaults.headers.common['Authorization']; // Delete authorization header for future requests
    })
    .catch(error => {
      //TODO: Handle error
    });
};

const loginUser = userObj => ({
  type: LOGIN_USER,
  payload: userObj
});

const logoutUser = () => ({
  type: LOGOUT_USER
});

const dev = {
  coreAPI: 'https://insights.whaleandjaguar.co'
};

const stage = {
  coreAPI: 'https://insights.whaleandjaguar.co'
};

const prod = {
  coreAPI: 'https://insights.whaleandjaguar.co'
};

let config = dev;

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    config = prod;
    break;
  case 'staging':
    config = stage;
    break;
  default:
    config = dev;
}

export default config;

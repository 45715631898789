/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';
import { Container, Row } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer id='footer' className={'footer' + (this.props.default ? ' footer-default' : '')}>
        <Container fluid={this.props.fluid ? true : false}>
          <p>COPYRIGHT © WHALE & JAGUAR {new Date().getFullYear()}</p>


          <ul className='nav'>
            <li className='nav-item'>
              <a className='nav-link' href='https://www.facebook.com/whaleandjaguar/'>
                <i className='fab fa-facebook fa-2x'></i>
              </a>
            </li>{' '}
            <li className='nav-item'>
              <a className='nav-link' href='https://www.instagram.com/whaleandjaguar_/'>
                <i className='fab fa-instagram fa-2x'></i>
              </a>
            </li>{' '}
            <li className='nav-item'>
              <a className='nav-link' href='https://www.linkedin.com/company/whaleandjaguar/'>
                <i className='fab fa-linkedin fa-2x'></i>
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='https://twitter.com/whaleandjaguar_'>
                <i className='fab fa-twitter fa-2x'></i>
              </a>
            </li>
          </ul>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;

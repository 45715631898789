import { LOGIN_USERFB, LOGOUT_USERFB } from '../constants/action-types';

export default function(state = false, action) {
  switch (action.type) {
    case LOGIN_USERFB:
      return action.payload;
    case LOGOUT_USERFB:
      localStorage.clear();
      window.location.reload();
      return false;
    default:
      return state;
  }
}

/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { userLoginFetch } from '../../../actions/index';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col
} from 'reactstrap';

import Spinner from '../../components/Spinner/Spinner';

function reducer(state, { field, value }) {
  return {
    ...state,
    [field]: value
  };
}

const initialForm = {
  username: '',
  password: ''
};

function Login() {
  const [nameFocus, setNameFocus] = useState(false);
  const [passFocus, setPassFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [form, disp] = useReducer(reducer, initialForm);

  const { username, password } = form;

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.toggle('login-page');

    // returned function will be called on component unmount
    return () => {
      document.body.classList.toggle('login-page');
    };
  }, []);

  useEffect(() => {
    if(localStorage.getItem('token')){
      history.push('/app/dashboard');
    }
    function login() {
      dispatch(userLoginFetch(form))
        .then(res => {
          history.push('/app/dashboard');
        })
        .catch(err => {
          if (err.detail === 'Invalid token.') {
            // Invalid token. By this point we have removed it from headers. Try to login again.
            login();
          } else {
            // Show login field errors.
            setLoading(false);
            console.log(err);
            if (err.Error) {
              setErrorMsg(err.Error);
            } else if (err.non_field_errors) {
              setErrorMsg(err.non_field_errors[0]);
            }
          }
        });
    }

    if (loading) {
      login();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = e => {
    disp({ field: e.target.name, value: e.target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!loading) {
      setLoading(true);
    }
  };

  return (
    <>
      <div className='content'>
        <Container>
          <Col className='ml-auto mr-auto' lg='4' md='6'>
            <Form className='form' onSubmit={handleSubmit}>
              <Card className='card-login card-white'>
                <CardHeader>
                  <img alt='...' src={window.location.origin + '/img/card-primary.png'} />
                  <CardTitle tag='h1'>Iniciar Sesión</CardTitle>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={classnames({
                      'input-group-focus': nameFocus
                    })}
                  >
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='tim-icons icon-email-85' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Usuario'
                      type='text'
                      name='username'
                      value={username}
                      onFocus={e => setNameFocus(true)}
                      onBlur={e => setNameFocus(false)}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      'input-group-focus': passFocus
                    })}
                  >
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='tim-icons icon-lock-circle' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Contraseña'
                      type='password'
                      name='password'
                      value={password}
                      onFocus={e => setPassFocus(true)}
                      onBlur={e => setPassFocus(false)}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <div style={{ height: '1rem' }}>{errorMsg ? <div style={{ color: 'red', fontSize: '0.75rem' }}> {errorMsg}</div> : null}</div>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className='mb-3'
                    style={{ fontSize: '0.875rem' }}
                    color='primary'
                    onClick={handleSubmit}
                    disabled={username || password ? false : true}
                    size='lg'
                  >
                    {loading ? <Spinner /> : <>Iniciar sesión</>}
                  </Button>
                  <div className='pull-right'>
                    
                  </div>
                  <div className='pull-left'>
                    
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default Login;

/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import reduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import { Router, Route, Switch, Redirect } from 'react-router-dom';

import AuthPage from 'views/auth-page/AuthPage';
import AppPage from 'views/app-page/AppPage';

import 'assets/css/nucleo-icons.css';
import 'react-notification-alert/dist/animate.css';
import 'assets/scss/black-dashboard-pro-react.scss?v=1.1.0';

const hist = createBrowserHistory();

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path='/auth' render={props => <AuthPage {...props} />} />
        <Route path='/app' render={props => <AppPage {...props} />} />
        <Redirect from='/' to='/app/dashboard' />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

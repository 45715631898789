/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

// reactstrap components
import { Navbar } from 'reactstrap';

function AuthNavbar() {
  const [color] = useState('navbar-transparent');

  return (
    <Navbar className={classnames('navbar-absolute fixed-top', color)} expand='lg'>
      <NavLink to='/'>
        <h1>Whale & Jaguar</h1>
      </NavLink>
    </Navbar>
  );
}

export default AuthNavbar;

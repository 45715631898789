/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AuthNavbar from '../components/Navbars/AuthNavbar.js';
import Footer from '../components/Footer/Footer.js';
import Login from './Login/Login';
import Register from './Register/Register';

import { connect } from 'react-redux';
import { fetchUser } from 'actions';

class AuthPage extends React.Component {
  componentDidMount() {
    this.props.fetchUser();
    document.documentElement.classList.remove('nav-open');
  }

  //TODO: Loading screen
  render() {
    if (this.props.auth === false) {
      return this.renderContent();
    } else if (this.props.auth === null) {
      return this.renderContent();
    } else {
      return <Redirect to='/app/dashboard' />;
    }
  }

  renderContent() {
    return (
      <>
        <AuthNavbar />
        <div className='wrapper wrapper-full-page' ref='fullPages'>
          <div className={'full-page'}>
            <Switch>
              <Route path='/auth/login' component={Login} />
              <Route path='/auth/register' component={Register} />
              <Redirect from='*' to='/auth/login' />
            </Switch>
            <Footer fluid />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, { fetchUser })(AuthPage);

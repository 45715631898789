import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// react plugin used to create charts
import { Line } from 'react-chartjs-2';
import FacebookLogin from 'react-facebook-login';


// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
} from 'reactstrap';

import axios from 'axios';
import config from '../../../config';
// core components

axios.defaults.baseURL = config.coreAPI;

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: 'data1',
      user: {},
      impression_ig : null,
      impression_ig_period: 'week',
      is_loading_im: true,
      profile_ig : null,
      profile_ig_period: 'week',
      is_loading_pf: true
    };
  }
  componentDidMount(){
    if (localStorage.getItem('Fb_exchange_token')){
      this.get_user_info();
      this.get_instagram_insights();
      this.get_instagram_profile()
      this.get_instagram_posts();
    }
  }
  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });
  };
  responseFacebook(response) {
    // console.log('FACE');
    if(response["error"]){
      return
    }
    console.log(response["error"]);
    if(response["status"] === "unknown"){
      return
    }
    if(response){
      localStorage.setItem('user_info',JSON.stringify({
        user: {
          name: response['name'],
          picture: response['picture']['data']['url']
        }
      }))
    }
    let user_id = response['userID'];
    let user_token = response['accessToken'];
    localStorage.setItem('Fb_user_token', user_token);
    axios
      .post('/insights/v1/facebook/fb_list_pages',{"user_id":user_id, "user_token": user_token})
      .then(res => {
        let page_token = res["data"]["data"][0]["access_token"];
        let page_id = res["data"]["data"][0]["id"];
        localStorage.setItem('Fb_page_info', JSON.stringify({"id": page_id,"token":page_token}));
        axios.post('/insights/v1/facebook/fb_exchange_token',{"token": page_token}).then( res => {
          let exchange_token = res["data"]["token"];
          localStorage.setItem('Fb_exchange_token', exchange_token);
          if (localStorage.getItem('Fb_exchange_token')){
            this.get_user_info();
            this.get_instagram_insights();
            this.get_instagram_posts();
          }
        });
      })
      .catch(err => {
        console.log(err)
      });
  }

  get_instagram_insights = (period = "day") =>{
    this.setState({
      ...this.state,
      is_loading_im: true
    })
    let long_token = localStorage.getItem('Fb_exchange_token');
    let page_id = JSON.parse(localStorage.getItem('Fb_page_info'))["id"];
    axios.post('/insights/v1/instagram/instagram_insights',{"page_id": page_id, "page_token": long_token,
                            "metric": ["reach"],
                            "period": period}).then(async (res)=>{
                              let data = res["data"]["data"][0]["values"];
                              
                              let url_instagram = res["data"]["paging"]["previous"];
                              for(let i = 0; i < 3 ; i++){
                                // eslint-disable-next-line no-loop-func
                                url_instagram = await axios.get(url_instagram).then((res2)=>{
                                  // console.log(res2)
                                  let data2 = res2["data"]["data"][0]["values"];
                                  data = data.concat(data2);
                                  return res2["data"]["paging"]["previous"];
                                });
                              }
                              data = data.map((item)=>{let date = item["end_time"].substr(0,10).split('-'); return {...item, "end_time": new Date(date[0],date[1]-1, date[2])}});
                              data.sort(function(a, b) {
                                a = new Date(a.end_time);
                                b = new Date(b.end_time);
                                return a<b ? -1 : a>b ? 1 : 0;
                              });
                              let labels = data.map((item)=>`${item["end_time"].getDate()}/${item["end_time"].getMonth() + 1}/${item["end_time"].getFullYear()}`);
                              // labels = labels.map((item)=>{let date = item.split('-'); return `${date[2]}/${date[1]}/${date[0]}`});
                              let values = data.map((item)=>item["value"]);
                              this.setBgChartData_im(labels,values,period)
                            });
  }

  get_instagram_profile= (period = "day") =>{
    this.setState({
      ...this.state,
      is_loading_pf: true
    })
    let long_token = localStorage.getItem('Fb_exchange_token');
    let page_id = JSON.parse(localStorage.getItem('Fb_page_info'))["id"];
    axios.post('/insights/v1/instagram/instagram_insights',{"page_id": page_id, "page_token": long_token,
                            "metric": ["profile_views"],
                            "period": period}).then(async (res)=>{
                              let data = res["data"]["data"][0]["values"];
                              
                              let url_instagram = res["data"]["paging"]["previous"];
                              for(let i = 0; i < 3 ; i++){
                                // eslint-disable-next-line no-loop-func
                                url_instagram = await axios.get(url_instagram).then((res2)=>{
                                  // console.log(res2)
                                  let data2 = res2["data"]["data"][0]["values"];
                                  data = data.concat(data2);
                                  return res2["data"]["paging"]["previous"];
                                });
                              }
                              data = data.map((item)=>{let date = item["end_time"].substr(0,10).split('-'); return {...item, "end_time": new Date(date[0],date[1]-1, date[2])}});
                              data.sort(function(a, b) {
                                a = new Date(a.end_time);
                                b = new Date(b.end_time);
                                return a<b ? -1 : a>b ? 1 : 0;
                              });
                              let labels = data.map((item)=>`${item["end_time"].getDate()}/${item["end_time"].getMonth() + 1}/${item["end_time"].getFullYear()}`);
                              // labels = labels.map((item)=>{let date = item.split('-'); return `${date[2]}/${date[1]}/${date[0]}`});
                              let values = data.map((item)=>item["value"]);
                              this.setBgChartData_pf(labels,values,period)
                            });
  }

  async get_instagram_posts(){
    let long_token = localStorage.getItem('Fb_exchange_token');
    let page_id = JSON.parse(localStorage.getItem('Fb_page_info'))["id"];
    let id_posts = await axios.post('/insights/v1/instagram/instagram_posts',{"page_id": page_id, "page_token": long_token,
                            "fields": ["id","caption","media_url","media_type","like_count","comments_count","permalink"]}).then((res)=>{
                              return res["data"]["data"];
                            });
    for(let i=0; i< 4;i++){
      let insights = await axios.post('/insights/v1/instagram/instagram_post_insights',{"page_id": page_id, "page_token": long_token,
      "metric":["reach","saved"], "period": "lifetime", "post_id": id_posts[i]["id"]}).then((res)=>{
        return res["data"]["data"];
      });
      if(insights){
        id_posts[i] = {...id_posts[i], "reach": insights[0]["values"][0]["value"], "saved": insights[1]["values"][0]["value"]}
      }
    }
      this.setState({
        ...this.state,
        posts_instagram : id_posts.slice(0,9)
      });
  }


  setBgChartData_im = (labels, values, period) => {
    this.setState({
      ...this.state,
      impression_ig : get_chart_engagement(labels, values, "Reach"),
      impression_ig_period: period,
      is_loading_im: false,
    });
  };
  setBgChartData_pf = (labels, values, period) => {
    this.setState({
      ...this.state,
      profile_ig : get_chart_engagement(labels, values, "Views"),
      profile_ig_period: period,
      is_loading_pf: false,
    });
  };
  
  get_user_info(){
    let long_token = localStorage.getItem('Fb_exchange_token');
    let page_id = JSON.parse(localStorage.getItem('Fb_page_info'))["id"];
    axios
      .post('/insights/v1/instagram/instagram_user',{"page_id":page_id, "page_token": long_token, "fields": ["username","name","biography","followers_count","follows_count","media_count","profile_picture_url","website"]})
      .then(res => {
        this.setState({
          ...this.state,
          user : res['data']
        });
      })
      .catch(err => {
        console.log(err)
      });
  }
render() {
  return (
    <>
      <div className='content'>
        <Row>
        {!localStorage.getItem('Fb_exchange_token')?
        <Col xs="12">
        <p style={{color: "white"}}>Go to the Facebook tab and log in</p>
        </Col>: null}
        {localStorage.getItem('Fb_exchange_token')?
        (<Col lg='3' md='6'>
            <Card className='card-stats'>
              <CardBody>
                <Row>
                  <Col xs='5'>
                    <div className='info-icon text-center icon-primary'>
                      <img alt="" src={this.state.user['profile_picture_url']}
                      style={{borderRadius:"50%"}}></img>
                    </div>
                  </Col>
                  <Col xs='7'>
                    <div className='numbers'>
                      <p className='card-category'>Usuario</p>
                      {this.state.user['username'] ? <CardTitle tag='h6'>{this.state.user['username']}</CardTitle> : <Spinner></Spinner>}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>) : null}
          {localStorage.getItem('Fb_exchange_token')?
        <Col lg='3' md='6'>
            <Card className='card-stats'>
              <CardBody>
                <Row>
                  <Col xs='5'>
                    <div className='info-icon text-center icon-success'>
                      <i className='tim-icons icon-user-run' />
                    </div>
                  </Col>
                  <Col xs='7'>
                    <div className='numbers'>
                      <p className='card-category'>Seguidores</p>
                      {this.state.user['followers_count'] ? <CardTitle tag='h4'>{this.state.user['followers_count']}</CardTitle> : <Spinner></Spinner>}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>: null}
          {localStorage.getItem('Fb_exchange_token')?
        <Col lg='3' md='6'>
            <Card className='card-stats'>
              <CardBody>
                <Row>
                  <Col xs='5'>
                    <div className='info-icon text-center icon-warning'>
                      <i className='tim-icons icon-video-66' />
                    </div>
                  </Col>
                  <Col xs='7'>
                    <div className='numbers'>
                      <p className='card-category'>Número post</p>
                      {this.state.user['media_count'] ? <CardTitle tag='h4'>{this.state.user['media_count']}</CardTitle> : <Spinner></Spinner>}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col> : null}
          {localStorage.getItem('Fb_exchange_token')?
          <Col lg='3' md='6'>
            <Card className='card-stats'>
              <CardBody>
                <Row>
                  <Col xs='5'>
                    <div className='info-icon text-center icon-danger'>
                      <i className='tim-icons icon-single-02' />
                    </div>
                  </Col>
                  <Col xs='7'>
                    <div className='numbers'>
                      <p className='card-category'>Seguidos</p>
                      {this.state.user['follows_count'] ? <CardTitle tag='h4'>{this.state.user['follows_count']}</CardTitle> : <Spinner></Spinner>}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>:null}
          {/* fin tarjetas */}
          {this.state.impression_ig == null? (<div></div>): 
            (<Col xs='12'>
              <Card className='card-chart'>
                <CardHeader>
                  <Row>
                    <Col className='text-left' sm='6'>
                      <CardTitle tag='h2'>Account reach</CardTitle>
                    </Col>
                    <Col sm='6'>
                      <ButtonGroup className='btn-group-toggle float-right' data-toggle='buttons'>
                        <Button
                          color='info'
                          id='0'
                          size='sm'
                          tag='label'
                          className={classNames('btn-simple', {
                            active: this.state.impression_ig_period === 'week'
                          })}
                          onClick={() => this.get_instagram_insights('week')}
                        >
                          <input defaultChecked name='options' type='radio' />
                          <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Semana</span>
                          <span className='d-block d-sm-none'>
                            <i className='tim-icons icon-single-02' />
                          </span>
                        </Button>
                        <Button
                          color='info'
                          id='1'
                          size='sm'
                          tag='label'
                          className={classNames('btn-simple', {
                            active: this.state.impression_ig_period === 'day'
                          })}
                          onClick={() => this.get_instagram_insights('day')}
                        >
                          <input name='options' type='radio' />
                          <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Día</span>
                          <span className='d-block d-sm-none'>
                            <i className='tim-icons icon-gift-2' />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.is_loading_im ?
                    (<Spinner style={{marginLeft:"20px"}}></Spinner>)
                  :
                  (<div className='chart-area'>
                    <Line data={this.state.impression_ig} options={chart_1_2_3_options} />
                  </div>)
                }
                </CardBody>
              </Card>
            </Col>)
            }
            {this.state.impression_ig && (<Col xs='3'>{tactip(this.state.impression_ig, true, 'reach')}</Col>)}
            {this.state.impression_ig && (<Col xs='3'>{tactip(this.state.impression_ig, false, 'reach')}</Col>)}
            {this.state.profile_ig == null? (<div></div>): 
            (<Col xs='12'>
              <Card className='card-chart'>
                <CardHeader>
                  <Row>
                    <Col className='text-left' sm='6'>
                      <CardTitle tag='h2'>Vistas del perfil</CardTitle>
                    </Col>
                    
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.is_loading_pf?
                    (<Spinner style={{marginLeft:"20px"}}></Spinner>)
                  :
                  (<div className='chart-area'>
                    <Line data={this.state.profile_ig} options={chart_1_2_3_options} />
                  </div>)
                }
                </CardBody>
              </Card>
            </Col>)
            }
            {this.state.profile_ig && (<Col xs='3'>{tactip(this.state.profile_ig, true, 'vistas')}</Col>)}
            {this.state.profile_ig && (<Col xs='3'>{tactip(this.state.profile_ig, false, 'vistas')}</Col>)}
            {localStorage.getItem('Fb_exchange_token')?
            <Col xs="12">
            <h2>Last posts</h2>
            </Col>: null}
            {localStorage.getItem('Fb_exchange_token')?
              this.state.posts_instagram ? cards_instagram_posts(this.state.posts_instagram): <Spinner></Spinner>
            :null}
        </Row>
      </div>
    </>
  );
}
}
export default Status;

function cards_instagram_posts(list_posts){
  let list_cards = [];
  for (let i=0; i< list_posts.length; i++){
    list_cards.push(card_instagram_post(list_posts[i]["media_url"],
                                      list_posts[i]["caption"],
                                      list_posts[i]["media_type"],
                                      list_posts[i]["like_count"],
                                      list_posts[i]["comments_count"],
                                      list_posts[i]["reach"],
                                      list_posts[i]["saved"],
                                      list_posts[i]["permalink"],
                                      list_posts[i]["id"],
                                      ));
  }
  return list_cards;
}

function card_instagram_post(media_url,caption ,media_type,likes,comments,reach ,saved ,url , id){
  return (<Col lg='4' md='6'  key={id}><Card>
    <CardBody>
    {media_type === "IMAGE" ? 
    <img src={media_url} alt=""></img> :
    media_type === "CAROUSEL_ALBUM" ? (<div style={{position:"relative"}}><img src={media_url} alt=""></img><div style={{position: "absolute",top:"10px", right:"10px",background: "rgba(0,0,0,0.5)", borderRadius:"50%", width:"30px", height:"30px", display:"flex", alignItems:"center", justifyContent:"center"}}>
    <i className='tim-icons icon-single-copy-04' style={{color:"white"}}/></div></div>) : 
    media_type === "VIDEO" ? (<video style={{width:"100%"}} src={media_url} controls/>): <div></div>}
    </CardBody>
    <CardHeader><CardTitle>{caption}</CardTitle>
    <div style={{marginBottom: "10px",padding:"5px 10px", borderRadius:"5px", display:"flex", justifyContent: "space-between", background:"#9055A2"}}>
      <div style={{display:"flex", alignItems:"center"}}>{likes}<i className='tim-icons icon-heart-2' style={{color:"white", marginLeft:"5px"}}/></div>
      <div style={{display:"flex", alignItems:"center"}}>{comments}<i className='tim-icons icon-chat-33' style={{color:"white", marginLeft:"5px"}}/></div>
      {reach !== undefined ? 
      <div style={{display:"flex", alignItems:"center"}}>{reach}<i className='tim-icons icon-zoom-split' style={{color:"white", marginLeft:"5px"}}/></div>: null
      }
      {saved !== undefined ? 
      <div style={{display:"flex", alignItems:"center"}}>{saved}<i className='tim-icons icon-book-bookmark' style={{color:"white", marginLeft:"5px"}}/></div>: null
      }
      <div style={{display:"flex", alignItems:"center"}}><a rel="noopener noreferrer" href={url} target="_blank"><i className='tim-icons icon-link-72' style={{color:"white", marginLeft:"5px"}}/></a></div>
    </div>
    </CardHeader>
  </Card></Col>)
}

let chart_1_2_3_options = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent"
        },
        ticks: {
          // suggestedMin: 1,
          // suggestedMax: 20,
          padding: 10,
          fontColor: "#9a9a9a"
        }
      }
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ]
  }
};

const tactip = (data, max_bool=true, title="'engagement'")=>{
  let data_values = data["datasets"][0]["data"];
  let value_mm;
  if (max_bool){
    value_mm  = Math.max(...data_values);
  }else{
    value_mm  = Math.min(...data_values);
  }
  let count_data = data_values.filter((value)=>value === value_mm).length;
  let indices = data_values.map((e, i) => e === value_mm ? i : '').filter(String)

  let styles_value = {
    background: '#2280F7', 
    borderRadius:"5px",
    padding: "5px",
    display: "flex",
    justifyContent: "Center"
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {max_bool?" Mayor": "Menor"} {title}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div style={{display:'flex', justifyContent:'space-around'}}>
          <div>
            <p>Valor: </p><h3 style={styles_value}>{value_mm}</h3>
          </div>
          <div>
            {count_data!==1 ? <p>Última fecha:</p> : <p>Fecha:</p>}
            <h3>{data["labels"][indices[indices.length - 1]]}</h3>
          </div>
        </div>
        {count_data!==1 && (<div style={{textAlign: "justify"}}>
          <hr style={{background:'rgba(255,255,255,0.5)', marginTop:'0px'}}/>
          <p>Nota: Hay más de un dia con el valor {max_bool?" máximo": "mínimo"}, se muestra la última fecha con este valor.</p>
        </div>)}
      </CardBody>
    </Card>
  )
}

function get_chart_engagement(labels, values, text){
  return {
    labels: labels,
    datasets: [
      {
        label: text,
        fill: true,
        borderColor: "#1f8ef1",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#1f8ef1",
        pointBorderColor: "rgba(255,255,255,0)",
        pointHoverBackgroundColor: "#1f8ef1",
        pointBorderWidth: 20,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 4,
        data: values
      }
    ]
  };
}

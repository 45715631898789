import { LOGIN_USER, LOGOUT_USER } from '../constants/action-types';

export default function(state = null, action) {
  switch (action.type) {
    case LOGIN_USER:
      return action.payload;
    case LOGOUT_USER:
      localStorage.clear();
      window.location.reload();
      return false;
    default:
      return state;
  }
}

/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useReducer } from 'react';
import classnames from 'classnames';

import { userRegisterFetch, userLoginFetch } from 'actions/index';
import { useHistory, NavLink } from 'react-router-dom';

import { useDispatch } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  FormFeedback
} from 'reactstrap';

import Spinner from '../../components/Spinner/Spinner';

const initialForm = {
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  password1: '',
  password2: ''
};

function reducer(state, { field, value }) {
  return {
    ...state,
    [field]: value
  };
}

function Register() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [form, disp] = useReducer(reducer, initialForm);

  const { username, email, password1, password2 } = form;

  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [passFocus, setPassFocus] = useState(false);
  const [passFocus2, setPassFocus2] = useState(false);
  const [loading, setLoading] = useState(false);
  //const [errorMsg, setErrorMsg] = useState('');
  const [errorObj, setErrorObj] = useState({});

  useEffect(() => {
    document.body.classList.toggle('register-page');

    // returned function will be called on component unmount
    return () => {
      document.body.classList.toggle('register-page');
    };
  }, []);

  useEffect(() => {
    function register() {
      dispatch(userRegisterFetch(form))
        .then(res => {
          const newForm = { username: form.username, password: form.password1 };
          dispatch(userLoginFetch(newForm))
            .then(res => {
              history.push('/app/dashboard');
            })
            .catch(err => {
              // User registered succesfully but couldn't login. TODO
            });
        })
        .catch(err => {
          if (err.detail === 'Invalid token.') {
            // Invalid token. By this point we have removed it from headers. Try to register again.
            register();
          } else {
            // Show register field errors.
            setLoading(false);

            // Convert string values to array to map over all errors in jsx.

            let errorFields = {};
            // eslint-disable-next-line
            Object.keys(err).map(key => {
              if (typeof err[key] === 'string') {
                err[key] = [err[key]];
              }
              errorFields[key.toLowerCase()] = err[key];
            });
            console.log(errorFields);

            setErrorObj(errorFields);
          }
        });
    }

    if (loading) {
      register();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleSubmit = event => {
    event.preventDefault();
    if (!loading) {
      setLoading(true);
    }
  };

  const handleChange = e => {
    disp({ field: e.target.name, value: e.target.value });
  };

  return (
    <>
      <div className='content'>
        <Container>
          <Col className='ml-auto mr-auto' lg='4' md='6'>
            <Card className='card-register card-white'>
              <CardHeader>
                <CardImg alt='...' src={require('assets/img/card-primary.png')} />
                <CardTitle tag='h4'>Registro</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className='form' onSubmit={handleSubmit}>
                  <InputGroup
                    className={classnames({
                      'input-group-focus': nameFocus
                    })}
                  >
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='tim-icons icon-single-02' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      invalid={errorObj.username ? true : false}
                      name='username'
                      placeholder='Username'
                      type='text'
                      value={username}
                      onFocus={e => setNameFocus(true)}
                      onBlur={e => setNameFocus(false)}
                      onChange={handleChange}
                    />
                    {errorObj.username
                      ? errorObj.username.map(err => {
                          return <FormFeedback key={err}>{err}</FormFeedback>;
                        })
                      : null}
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      'input-group-focus': emailFocus
                    })}
                  >
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='tim-icons icon-email-85' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      invalid={errorObj.email ? true : false}
                      placeholder='Email'
                      type='email'
                      name='email'
                      required
                      value={email}
                      onFocus={e => setEmailFocus(true)}
                      onBlur={e => setEmailFocus(false)}
                      onChange={handleChange}
                    />
                    {errorObj.email
                      ? errorObj.email.map(err => {
                          return <FormFeedback key={err}>{err}</FormFeedback>;
                        })
                      : null}
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      'input-group-focus': passFocus
                    })}
                  >
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='tim-icons icon-lock-circle' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      invalid={errorObj.password1 ? true : false}
                      placeholder='Password'
                      type='password'
                      name='password1'
                      value={password1}
                      onFocus={e => setPassFocus(true)}
                      onBlur={e => setPassFocus(false)}
                      onChange={handleChange}
                    />
                    {errorObj.password1
                      ? errorObj.password1.map(err => {
                          return <FormFeedback key={err}>{err}</FormFeedback>;
                        })
                      : null}
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      'input-group-focus': passFocus2
                    })}
                  >
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='tim-icons icon-lock-circle' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      invalid={errorObj.password2 || errorObj.password ? true : false}
                      placeholder='Confirm password'
                      type='password'
                      name='password2'
                      value={password2}
                      onFocus={e => setPassFocus2(true)}
                      onBlur={e => setPassFocus2(false)}
                      onChange={handleChange}
                    />
                    {errorObj.password2
                      ? errorObj.password2.map(err => {
                          return <FormFeedback key={err}>{err}</FormFeedback>;
                        })
                      : null}
                    {errorObj.password
                      ? errorObj.password.map(err => {
                          return <FormFeedback key={err}>{err}</FormFeedback>;
                        })
                      : null}
                  </InputGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  block
                  className='mb-3'
                  color='primary'
                  disabled={username && password1 && password2 && email ? false : true}
                  onClick={handleSubmit}
                  size='lg'
                >
                  {loading ? <Spinner /> : <>Continuar</>}
                </Button>
                <div className='pull-right'>
                  <h6>
                    <NavLink className='link footer-link' style={{ fontSize: '10px', textDecoration: 'none' }} to='/auth/login'>
                      Iniciar Sesion
                    </NavLink>
                  </h6>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default Register;

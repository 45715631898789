import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { fetchUser } from 'actions';

import App from './App';
// import LoadingLogo from '../components/LoadingLogo/LoadingLogo';

function AppPage(props) {
  // const auth = useSelector(state => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (localStorage.getItem('token')) {
    
    return <App {...props} />;
  }else {
    return <Redirect to='/auth/login' />;
  }
}

export default AppPage;
